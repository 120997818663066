<script>
import { useRoute } from "vue-router";
import { VueAgile } from "vue-agile";

import IconArrowRight from "@/components/Icons/IconArrowRight.vue";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import { useFetch, useTitle } from "@vueuse/core";
import { computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    agile: VueAgile,
    IconArrowRight,
    IconArrowLeft,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const currentRoute = computed(() => route.params.slug);
    const categories = computed(() => store.getters.getSideBarMenu);

    function fetchCategory(slug) {
      let id;
      switch (slug) {
        case "sport":
          id = 2;
          break;
        case "vacation":
          id = 299;
          break;
        case "management":
          id = 335;
          break;
      }
      let url = "https://admin.dss-sport.ru/wp-json/wp/v2/pages/" + id;
      const { data } = useFetch(url).get().json();
      store.commit("SET_SIDE_BAR_MENU", {
        sideBarMenu: data,
      });
    }

    onMounted(() => {
      fetchCategory(currentRoute.value);
    });

    watch(
      () => route.params.slug,
      async () => {
        try {
          fetchCategory(currentRoute.value);
        } catch (e) {
          console.error(e);
        }
      }
    );

    // eslint-disable-next-line no-unused-vars
    const title = useTitle(
      currentRoute.value === "sport"
        ? "Спорт"
        : "Государственное автономное учреждение Республики Бурятия Дирекция спортивных сооружений" ||
          currentRoute.value === "vacation"
        ? "Отдых"
        : "Государственное автономное учреждение Республики Бурятия Дирекция спортивных сооружений" ||
          currentRoute.value === "management"
        ? "Дирекция"
        : "Государственное автономное учреждение Республики Бурятия Дирекция спортивных сооружений"
    );

    return {
      currentRoute,
      categories,
    };
  },
};
</script>
<template>
  <div class="category category-view">
    <div class="category__wrapper">
      <div class="carousel">
        <agile :dots="false" :autoplay="true" :autoplay-speed="5000">
          <div class="slide" v-for="n in 6" :key="n" :class="`slide--${n}`">
            <div class="carousel__item">
              <img
                src="../assets/DSC09321_1.png"
                alt="Example slide description"
                loading="lazy"
              />
              <div class="carousel__content">
                <span class="carousel__badge">водные виды спорта</span>
                <h3 class="carousel__title">
                  СПОРТИВНЫЙ ПЛАВАТЕЛЬНЫЙ БАССЕЙН В ФСК
                </h3>
                <span class="carousel__subtitle">
                  Приглашаем вас в самый большой плавательный бассейн города
                  Улан-Удэ.
                </span>
                <router-link to="/page/sport/235"
                  ><button class="btn btn-primary">
                    подробнее
                  </button></router-link
                >
              </div>
            </div>
          </div>
          <template v-slot:prevButton><icon-arrow-left /></template
          ><template v-slot:nextButton><icon-arrow-right /></template>
        </agile>
      </div>
      <div class="jumbotron">
        <div class="jumbotron__wrapper">
          <!-- <h1 v-if="currentRoute === 'sport'" class="jumbotron__title">
            спорт
          </h1>
          <h1 v-else-if="currentRoute === 'vacation'" class="jumbotron__title">
            отдых
          </h1>
          <h1
            v-else-if="currentRoute === 'management'"
            class="jumbotron__title"
          >
            дирекция
          </h1> -->
          <h1 class="jumbotron__title">
            {{ categories?.title?.rendered }}
          </h1>
          <!-- <div class="jumbotron__content">
            Безусловно, существующая теория предполагает независимые способы
            реализации инновационных методов управления процессами. Начало
            повседневной работы по формированию позиции предопределяет высокую
            востребованность глубокомысленных рассуждений. Имеется спорная точка
            зрения, гласящая примерно следующее
          </div> -->
          <div
            class="jumbotron__content"
            v-html="categories?.excerpt?.rendered"
          ></div>
        </div>
      </div>
      <div class="category__lists">
        <div class="category__lists__wrapper">
          <router-link
            class="category__lists__card"
            v-for="(item, n) in categories?.childrens"
            :key="item.id"
            :class="`card--${n}`"
            :to="{
              name: 'Page',
              params: { type: currentRoute, slug: item.id },
            }"
          >
            <div class="card--image">
              <img
                :src="
                  item?.acf?.image_url ||
                  '../img/archeryworldcup2016stage3antalyal_tdvzusyzbl_1.png'
                "
                alt="archery"
              />
            </div>
            <div class="card--content">
              <h4 class="card--title">{{ item?.title }}</h4>
              <span class="card--desc">{{ item?.acf?.short_desc }}</span>
            </div>
          </router-link>
        </div>
      </div>
      <p></p>
    </div>
  </div>
</template>

<style lang="sass">
@import "../styles/scss/_mixins.scss"

.agile
  & .agile__list
    height: 430px
  & .agile__slide
      position: relative
      height: 430px
      &::after
        position: absolute
        content: ''
        display: block
        top: 0
        left: 0
        height: 100%
        width: 100%
        background: #0551a033
        mix-blend-mode: multiply
  & .agile__actions
    position: absolute
    bottom: $default-padding*2
    right: $default-padding*2
    gap: $default-padding*.3
    & .agile__nav-button
      width: $default-padding*3.5
      height: $default-padding*3.4
      background: rgba(255, 255, 255, 0.5)
      border: none
      cursor: pointer
      transition: all .2s
      @include d-flex(row, center, center, null, null, null)
      &:active
        transform: translate($default-padding*.05, $default-padding*.05)
      &:disabled
        opacity: .6
        cursor: not-allowed
      &.agile__nav-button--prev
        border-radius: 10px 0px 0px 10px
      &.agile__nav-button--next
        border-radius: 0px 10px 10px 0px

.carousel
  &__item
    width: 100%
    height: 100%
    & img
      width: 100%
      height: 100%
      object-fit: cover
  &__content
    @include d-flex(column, flex-start, flex-start, null, null, null)
    position: absolute
    top: $default-padding*3.75
    left: $default-padding*4.5
    z-index: 2
    @media only screen and (max-width: $sizes-sm)
      top: $default-padding*2.75
      left: $default-padding*3.5
    & .btn
      margin-top: $default-padding*1.5
  &__badge
    color: #EA3838
    letter-spacing: 0.05em
    text-transform: uppercase
    font-size: $base-font-size*.8
    line-height: $base-font-size
    font-weight: 600
  &__title
    color: $base-color
    font-size: $base-font-size*2.2
    font-weight: 800
    text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.15)
    line-height: $base-font-size*2.8
    letter-spacing: 0.05em
    text-transform: uppercase
    margin: $default-padding 0 $default-padding*.5
    max-width: 690px
    @media only screen and (max-width: $sizes-sm)
      font-size: $base-font-size*1.5
      line-height: $base-font-size*2
  &__subtitle
    color: $base-color
    font-weight: 500
    max-width: 450px
    @media only screen and (max-width: $sizes-sm)
      font-size: $base-font-size*.8
      max-width: 320px

.jumbotron
  position: relative
  @media only screen and (max-width: $sizes-sm)
    margin-top: $default-padding
  &__wrapper
    @include d-flex(row, space-between, center, null, null, $default-padding*3.75)
    max-width: $default-padding*60.4
    margin: auto
    @media only screen and (max-width: $sizes-md)
      flex-direction: column
      gap: $default-padding*2
  &__title
    font-size: $base-font-size*6
    color: $primary-color
    font-weight: 800
    text-transform: uppercase
    line-height: $base-font-size*7.3
    margin: 0
    @media only screen and (max-width: $sizes-md)
      font-size: $base-font-size*4
      line-height: $base-font-size*5.3
  &__content
    line-height: $base-font-size*1.5
    margin-top: $default-padding*4.5
    max-width: $default-padding*32.8
    @media only screen and (max-width: $sizes-md)
      margin-top: 0

.category__lists
  position: relative
  max-width: $sizes-exl
  margin: $default-padding*3.05 auto 0
  &__wrapper
    display: grid
    grid-template-columns: 1fr 1fr
    gap: $default-padding $default-padding*1.3
    @media only screen and (max-width: $sizes-lg - $default-padding*2)
      grid-template-columns: 1fr 1fr
    @media only screen and (max-width: $sizes-md)
      display: flex
      flex-direction: column
  &__card
    @include d-flex(row, space-between, center, null, null, null)
    border-radius: 10px
    overflow: hidden
    background: #fafafa
    border: 1px solid #f3f3f3
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12)
    max-height: 350px
    position: relative
    &:hover
      & .card--title
        color: $primary-color
    @media only screen and (max-width: $sizes-xs + $default-padding*6)
      max-height: 100%
    @media only screen and (max-width: $sizes-sm)
        margin: 0 -7px
    &.card--1
      grid-column: 1 / 2
      grid-row: 1 / 3
      max-height: 100%
      & .card--content
        position: absolute
        bottom: 2%
        left: 0
        right: 0
        width: 100%
        height: auto
        color: $base-color
        padding: $default-padding*1.5
        max-width: 650px
        @media only screen and (max-width: $sizes-md)
          position: relative
          width: 50%
          color: inherit
      & .card--desc
        color: $base-color
      & .card--image
        width: 100%
        &::after
          content: ''
          position: absolute
          bottom: -($default-padding)
          left: 0
          right: 0
          height: 170px
          filter: blur(8px)
          background: #0000005c
          @media only screen and (max-width: $sizes-md)
            height: 0
        @media only screen and (max-width: $sizes-md)
          width: 50%
    &.card--6
      grid-column: 2 / 3
      grid-row: 3 / 5
      max-height: 100%
      & .card--content
        position: absolute
        bottom: 2%
        left: 0
        right: 0
        width: 100%
        height: auto
        color: $base-color
        padding: $default-padding*1.5
        max-width: 650px
        @media only screen and (max-width: $sizes-md)
          position: relative
          width: 50%
          color: inherit
      & .card--desc
        color: $base-color
      & .card--image
        width: 100%
        &::after
          content: ''
          position: absolute
          bottom: -($default-padding)
          left: 0
          right: 0
          height: 170px
          filter: blur(8px)
          background: #0000005c
          @media only screen and (max-width: $sizes-md)
            height: 0
        @media only screen and (max-width: $sizes-md)
          width: 50%
    &.card--7
      grid-column: 1 / 2
      grid-row: 5 / 7
      max-height: 100%
      & .card--content
        position: absolute
        bottom: 2%
        left: 0
        right: 0
        width: 100%
        height: auto
        color: $base-color
        padding: $default-padding*1.5
        max-width: 650px
        @media only screen and (max-width: $sizes-md)
          position: relative
          width: 50%
          color: inherit
      & .card--desc
        color: $base-color
      & .card--image
        width: 100%
        &::after
          content: ''
          position: absolute
          bottom: -($default-padding)
          left: 0
          right: 0
          height: 170px
          filter: blur(8px)
          background: #0000005c
          @media only screen and (max-width: $sizes-md)
            height: 0
        @media only screen and (max-width: $sizes-md)
          width: 50%
    &.card--10
      grid-column: 2 / 3
      grid-row: 7 / 9
      max-height: 100%
      & .card--content
        position: absolute
        bottom: 2%
        left: 0
        right: 0
        width: 100%
        height: auto
        color: $base-color
        padding: $default-padding*1.5
        max-width: 650px
        @media only screen and (max-width: $sizes-md)
          position: relative
          width: 50%
          color: inherit
      & .card--desc
        color: $base-color
      & .card--image
        width: 100%
        &::after
          content: ''
          position: absolute
          bottom: -($default-padding)
          left: 0
          right: 0
          height: 170px
          filter: blur(8px)
          background: #0000005c
          @media only screen and (max-width: $sizes-md)
            height: 0
        @media only screen and (max-width: $sizes-md)
          width: 50%
    &.card--13
      grid-column: 1 / 2
      grid-row: 9 / 11
      max-height: 100%
      & .card--content
        position: absolute
        bottom: 2%
        left: 0
        right: 0
        width: 100%
        height: auto
        color: $base-color
        padding: $default-padding*1.5
        max-width: 650px
        @media only screen and (max-width: $sizes-md)
          position: relative
          width: 50%
          color: inherit
      & .card--desc
        color: $base-color
      & .card--image
        width: 100%
        &::after
          content: ''
          position: absolute
          bottom: -($default-padding)
          left: 0
          right: 0
          height: 170px
          filter: blur(8px)
          background: #0000005c
          @media only screen and (max-width: $sizes-md)
            height: 0
        @media only screen and (max-width: $sizes-md)
          width: 50%
    &.card--16
      grid-column: 2 / 3
      grid-row: 11 / 13
      max-height: 100%
      & .card--content
        position: absolute
        bottom: 2%
        left: 0
        right: 0
        width: 100%
        height: auto
        color: $base-color
        padding: $default-padding*1.5
        max-width: 650px
        @media only screen and (max-width: $sizes-md)
          position: relative
          width: 50%
          color: inherit
      & .card--desc
        color: $base-color
      & .card--image
        width: 100%
        &::after
          content: ''
          position: absolute
          bottom: -($default-padding)
          left: 0
          right: 0
          height: 170px
          filter: blur(8px)
          background: #0000005c
          @media only screen and (max-width: $sizes-md)
            height: 0
        @media only screen and (max-width: $sizes-md)
          width: 50%
    &.card--17
      grid-column: 1 / 2
      grid-row: 13 / 15
      max-height: 100%
      & .card--content
        position: absolute
        bottom: 2%
        left: 0
        right: 0
        width: 100%
        height: auto
        color: $base-color
        padding: $default-padding*1.5
        max-width: 650px
        @media only screen and (max-width: $sizes-md)
          position: relative
          width: 50%
          color: inherit
      & .card--desc
        color: $base-color
      & .card--image
        width: 100%
        &::after
          content: ''
          position: absolute
          bottom: -($default-padding)
          left: 0
          right: 0
          height: 170px
          filter: blur(8px)
          background: #0000005c
          @media only screen and (max-width: $sizes-md)
            height: 0
        @media only screen and (max-width: $sizes-md)
          width: 50%
    @media only screen and (max-width: $sizes-lg - $default-padding*2)
      flex-direction: row !important
    @media only screen and (max-width: $sizes-xs + $default-padding*6)
      flex-direction: column !important
    &:nth-child(even)
      flex-direction: row-reverse
    &.card--0
      flex-direction: row-reverse
    &.card--11
      flex-direction: row
    & div
      width: 50%
      height: 100%
      @media only screen and (max-width: $sizes-xs + $default-padding*6)
        width: 100% !important
    & img
      width: 100%
      height: 100%
      object-fit: cover
    & .card
      &--content
        padding: $default-padding*1.3
        flex: 1
        @media only screen and (max-width: $sizes-xs + $default-padding*6)
          flex: none
        @media only screen and (max-width: $sizes-sm)
          position: absolute !important
          top: 75%
          left: -15px !important
          color: #fff !important
          background: #00000025
      &--title
        font-size: $base-font-size*1.2
        line-height: $base-font-size*1.3
        font-weight: 700
        text-transform: uppercase
        margin: $default-padding*1.5 0
        transition: color 0.3s
        @media only screen and (max-width: $sizes-xs + $default-padding*6)
          padding: 0 $default-padding*.7
          margin: 0 0 $default-padding
      &--desc
        color: $sub-text-color
        font-size: $base-font-size*.85
        @media (max-width: $sizes-lg)
          display: none
</style>
